import heroImgOne from '../../assets/img/Comunidad/comunidad.webp';
import heroImgTwo from '../../assets/img/Hero/hero2.webp';
import heroImgThree from '../../assets/img/Comunidad/corredores.webp';


import CardImgOne from '../../assets/img/Comunidad/corredores.webp';
import CardImgTwo from '../../assets/img/Comunidad/propiedad.webp';
import CardImgThree from '../../assets/img/Comunidad/mudanza.webp';
import CardImgFour from '../../assets/img/Comunidad/politicas&seguridad.webp';
import CardImgFive from '../../assets/img/Comunidad/oportunidades.webp';
import CardImgSix from '../../assets/img/Comunidad/comoUsar.webp';
import CardImgSeven from '../../assets/img/Comunidad/product.webp';
// import { HiRefresh } from 'react-icons/hi';

export const contentSlickCommunity = [
    {
      id:1,
      imgHero: heroImgOne,
      icon:'',
      headings: 'Comunidad',
      texts:'Nuestras soluciones hacen más agil tu negocio', 
    },
    {
      id:2,
      imgHero:heroImgTwo,
      icon:'',
      headings: 'Comunidad',
      texts:'Como es nuestra comunidad.', 
    },
    {
      id:3,
      imgHero: heroImgThree,
      icon:'',
      headings: 'Comunidad',
      texts:'Quienes somos.', 

    },
  ]

  export const contentFirstCardCommunity = [
    {
      id:1,
      imgCard: CardImgOne,
      subheading:'Asociate y más',
      headings: 'Corredores asociados',
      texts:'Encuentra socios similares aquí', 
      href:'/portal-corredores'
    },
    {
      id:2,
      imgCard:CardImgTwo,
      subheading:'Canjes y más',
      headings: 'Portal de propiedades',
      texts:'Portal inmobiliario acorde a tus necesidades', 
    },
  ]

  export const contentSecondCardCommunity = [
    {
      id:1,
      imgCard: CardImgFive,
      subheading:'Nuevas oportunidades',
      headings: {first:'Oportunidad de clientes', second:''},
      texts:'Encuentra nuevas oportunidades de clientes',
      href:'' ,
      href2:'' 
    },
    {
      id:2,
      imgCard:CardImgThree,
      subheading:'Servicios y más',
      headings: {first:'Servicios Externos', second:''},
      texts:'Conoce todos los servicios que tenemos.',
      href:'',
      href2:'' 
    },
    {
      id:3,
      imgCard:CardImgFour,
      subheading:'Politicas y más',
      headings: {first:'Politicas y', second:'Cookies'},
      texts:'Actualizaciones importantes', 
      href:'/politicas-de-privacidad' ,
      href2:'/politicas-de-cookies' 
    },
  ]

  export const contentThirtyCardCommunity = [
    {
      id:1,
      imgCard: CardImgSix,
      subheading:'Cómo usar',
      headings: 'Cómo usar YoKanjeo App',
      texts:'Deseas obtener un tutorial para la aplicación', 
    },
    {
      id:2,
      imgCard:CardImgSeven,
      subheading:'Produto y más',
      headings: 'Producto y carácteristicas',
      texts:'Revisar nuestro producto, carácteristicas y funciones', 
    },
  ]

  export const  Realtors = [
    {
      id:1,
      // img:'',
      realtor:'admin',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.38926870500395,
      latitude: -33.141351410073764,

    },
    {
      id:2,
      // img:'',
      realtor:'corr',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.28926870500395,
      latitude: -33.441351410073764,


    },
    {
      id:3,
      // img:'',
      realtor:'corredor',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.88926870500395,
      latitude: -33.741351410073764,

    },
    {
      id:4,
      // img:'',
      realtor:'axel',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.28926870500395,
      latitude: -33.541351410073764,

    },
    {
      id:5,
      // img:'',
      realtor:'axel',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.98926870500395,
      latitude: -33.641351410073764,
    },
    {
      id:6,
      // img:'',
      realtor:'Tiro al blanco',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.48926870500395,
      latitude: -32.441351410073724,
    },
    {
      id:7,
      // img:'',
      realtor:'Tiro al blanco',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.38926870500395,
      latitude: -32.241351410073764,
    },
    {
      id:8,
      // img:'',
      realtor:'Tiro al blanco',
      ubi:'Av. Siempre viva 312, Región Metropolitana',
      longitude:-70.68926870500395,
      latitude: -33.241351410073764,
    },

];