import MainNav from './main-nav';

const Header = () => {
  return (
    <header className="tw-z-50  tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bg-transparent">
      <MainNav />
    </header>
  );
};

export default Header;
