// import { FaTruckMoving, AiFillSafetyCertificate } from '../components/icon';

export const navigationData = [
  {
    id: 1,
    name: 'Inicio',
    href: '/',
  },
  {
    id: 3,
    name: 'Cómo funciona',
    href: '/como-funciona',  
     
  },
  {
    id: 4,
    name: 'Comunidad',
    href: '/comunidad',  
    // href: '#como-funciona',  
  },
  {
    id: 5,
    name: 'Nosotros',
    href: '/nosotros',  
  },
  {
    id: 6,
    name: 'Mi cuenta',
    href: 'https://keysoft.app/sign-in',
  },
];
