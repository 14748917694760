import {
  BsFacebook,
  BsInstagram,
  BsYoutube,
  BsLinkedin,
} from '../../components/icon';

export const footerSMediaNavigation = [
  {
    id: 1,
    name: 'Facebook',
    href: 'https://www.facebook.com/profile.php?id=100095538132053&mibextid=2JQ9oc',
    icon: <BsFacebook />,
    color: 'tw-text-custom-facebook',
  },
  {
    id: 2,
    name: 'Instagram',
    href: 'https://www.instagram.com/',
    icon: <BsInstagram />,
    color: 'tw-text-custom-facebook',
  },
  {
    id: 3,
    name: 'Twitter',
    href: 'https://twitter.com/',
    icon: '',
    iconImg: '/images/footer/logo-x.png',
    color: 'tw-text-custom-facebook',
  },
  {
    id: 4,
    name: 'Youtube',
    href: 'https://www.youtube.com/',
    icon: <BsYoutube />,
    color: 'tw-text-custom-facebook',
  },
  {
    id: 5,
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/in/',
    icon: <BsLinkedin />,
    color: 'tw-text-custom-facebook',
  },
];

export const footerNavigation = [
  {
    id: 1,
    name: 'Inicio',
    href: '/',
  },
  {
    id: 2,
    name: 'Cómo funciona',
    href: '/como-funciona',
  },
  {
    id: 3,
    name: 'Comunidad',
    href: '/comunidad',
  },
  {
    id: 4,
    name: 'Nosotros',
    href: '/nosotros',
  },
];
